import React from 'react'
import useTranslation from '@hooks/useTranslation'

import { ReactComponent as BerlinerIcon } from '@static/svgs/berliner-kurier.svg'
import { ReactComponent as FazIcon } from '@static/svgs/faz.svg'
import { ReactComponent as HorizontIcon } from '@static/svgs/horizont.svg'
import { ReactComponent as ManagerMIcon } from '@static/svgs/manager-magazine.svg'
import { ReactComponent as ZdfIcon } from '@static/svgs/zdf.svg'

import LogosPack from './LogosPack'

const InTheNews = () => {
  const { labelStrings } = useTranslation()

  const logosList = [
    {
      Icon: HorizontIcon,
      link:
        'https://www.horizont.net/marketing/kommentare/3-creator-to-watch-diese-tiktok-creator-sindim-august-durch-die-decke-gegangen-185455?utm_source=Browser&utm_medium=Push-Notification&utm_campaign=CleverPush&cleverPushNotificationId=hoybTccDryEp9bPxQ&cleverPushBounceUrl=https%3A%2F%2Fwww.horizont.net%2F&crefresh=1',
    },
    { Icon: FazIcon, link: 'https://drive.google.com/file/d/1HDxO-hZHpAolkpkRoxsKcZ07ANJ5haqX/view' },
    {
      Icon: ManagerMIcon,
      link:
        'https://www.manager-magazin.de/lifestyle/leute/tiktok-die-ersten-stars-kassieren-bereits-millionen-us-dollar-a-19a6815b-8cf6-42bc-9d2e-20dff70b744b',
    },
    { Icon: ZdfIcon, link: 'https://www.zdf.de/nachrichten/panorama/tiktok-younes-zarou-100.html' },
    { Icon: BerlinerIcon, link: 'https://www.wuv.de/agenturen/erste_reine_tiktok_agentur_startet_in_deutschland' },
  ]

  return <LogosPack title={labelStrings.webinarOct2020.inTheNews} logosList={logosList} />
}

export default InTheNews
