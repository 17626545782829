import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import useTranslation from '@hooks/useTranslation'

import { TransparentButton } from '@common/buttons/commonButtons'
import { Paragraph, List } from '@common/CommonStyles'

const Wrapper = styled.div`
  ${props => props.theme.flex.centerAllColumn}
  ${props => props.theme.media.lessThan('mid')`
    // flex-direction: column-reverse;
`}
`

const Button = styled(TransparentButton)`
  background: ${props => (props.isSelected ? props.theme.color.cornflowerBlue2 : '#d5d5d5')} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  font-weight: bold;
  font-size: 13px;
  border: none;
  margin: 5px 10px;
`

const ButtonsGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  ${props =>
    props.withTopMargin &&
    `
    margin: 30px 0;
  `}
  ${props => props.theme.media.lessThan('mobile')`
    width: 90%;
    margin: 0 auto;
    ${props.withTopMargin && 'margin-top: 30px;'}
`}
`

const TitleWrapper = styled.div`
  ${props => props.theme.flex.centerAll}
  width: 100%;
  padding: 0 5%;
  max-width: 900px;
`

const Title = styled.h3`
  color: ${props => props.theme.color.cornflowerBlue2};
  font-weight: 900;
  font-size: 50px;
  margin: 20px 0;
  ${props => props.theme.media.lessThan('mobile')`
  font-size: 40px;
`}
`

const DescWrapper = styled.div`
  max-width: 1100px;
  width: 100%;
  padding: 0 5%;
  ${props => props.theme.flex.centerAllColumn}
`

const Line = styled.div`
  // width: 50%;

  width: -webkit-fill-available;
  // max-width: 100px;
  border-top: 1px solid #e6e6e6;
  margin: 0 20px;
  ${props => props.theme.media.lessThan('mid')`
  display: none;
`}
`

const SpeakersNav = ({ selectedNav, idsForScroll, withHeader }) => {
  const { labelStrings } = useTranslation()

  const { speakers } = labelStrings.webinarOct2020

  const buttons = [
    {
      label: speakers.moderators.title,
      isSelected: selectedNav === speakers.moderators.title,
      elemId: idsForScroll.moderators,
    },
    {
      label: speakers.creators.title,
      isSelected: selectedNav === speakers.creators.title,
      elemId: idsForScroll.creators,
    },
    {
      label: speakers.marketers.title,
      isSelected: selectedNav === speakers.marketers.title,
      elemId: idsForScroll.marketers,
    },
    { label: speakers.brands.title, isSelected: selectedNav === speakers.brands.title, elemId: idsForScroll.brands },
  ]

  const buttonClickHandler = elemId => () => {
    if (elemId) {
      document.getElementById(elemId).scrollIntoView({
        behavior: 'smooth',
        // block: 'center'
      })
    }
  }

  return (
    <Wrapper>
      {withHeader && (
        <>
          <TitleWrapper>
            <Line />
            <Title>{speakers.title}</Title>
          </TitleWrapper>
          <DescWrapper>
            <Paragraph>{labelStrings.webinarOct2020.presentation.desc.fourthParagraph}</Paragraph>
            <List>
              {labelStrings.webinarOct2020.presentation.desc.list.map(item => {
                return <li key={item}>{item}</li>
              })}
            </List>
          </DescWrapper>
        </>
      )}
      <ButtonsGroup withTopMargin={!withHeader}>
        {buttons.map(b => {
          return (
            <Button key={b.label} isSelected={b.isSelected} onClick={buttonClickHandler(b.elemId)}>
              {b.label}
            </Button>
          )
        })}
      </ButtonsGroup>
    </Wrapper>
  )
}

SpeakersNav.propTypes = {
  selectedNav: PropTypes.string,
  idsForScroll: PropTypes.object,
  withHeader: PropTypes.bool,
}

SpeakersNav.defaultProps = {
  selectedNav: '',
  idsForScroll: {},
  withHeader: false,
}

export default SpeakersNav
