import React from 'react'
import styled from 'styled-components'
import useTranslation from '@hooks/useTranslation'

import ParticipantsSection from '@common/contents/webinarOct2020/ParticipantsSection'

const Wrapper = styled.div`
  margin: 30px 0 50px 0;
`

const ParticipantsContainer = () => {
  const { labelStrings } = useTranslation()

  const IDS_FOR_SCROLL = {
    moderators: 'moderators',
    creators: 'creators',
    marketers: 'marketers',
    brands: 'brands',
  }

  const { speakers } = labelStrings.webinarOct2020

  const sections = [
    { list: speakers.moderators.list, selectedNav: speakers.moderators.title, id: IDS_FOR_SCROLL.moderators },
    { list: speakers.creators.list, selectedNav: speakers.creators.title, id: IDS_FOR_SCROLL.creators },
    { list: speakers.marketers.list, selectedNav: speakers.marketers.title, id: IDS_FOR_SCROLL.marketers },
    { list: speakers.brands.list, selectedNav: speakers.brands.title, id: IDS_FOR_SCROLL.brands },
  ]

  return (
    <Wrapper>
      {sections.map((s, idx) => {
        const { list, selectedNav, id } = s
        const withHeader = idx === 0
        return (
          <ParticipantsSection
            key={id}
            list={list}
            selectedNav={selectedNav}
            idsForScroll={IDS_FOR_SCROLL}
            id={id}
            withHeader={withHeader}
          />
        )
      })}
    </Wrapper>
  )
}

export default ParticipantsContainer
