import React from 'react'
import styled from 'styled-components'
// import { MaxWidthWrapper } from '@common/CommonStyles'
import WebinarPresentation from '@common/contents/webinarOct2020/WebinarPresentation'
// import PageScrollHorizontalProgressBar from '@common/PageScrollHorizontalProgressBar'
import ParticipantsContainer from '@containers/webinars/ParticipantsContainer'
import AboutWecreate from '@common/contents/webinarOct2020/AboutWecreate'
import InTheNews from '@common/contents/webinarOct2020/InTheNews'
import References from '@common/contents/webinarOct2020/References'
import Timetable from '@common/contents/webinarOct2020/Timetable'
import BookASpotContainer from './webinars/BookASpotContainer'

const Wrapper = styled.div`
  background: ${props => props.theme.color.athensGray} 0% 0% no-repeat padding-box;
`

const BGWrapper = styled.div`
  // url('../../svgs/flagBcg.svg'), url('../../svgs/flagBcg.svg')  center left, right top
  background-image: url('../../svgs/flagBcg.svg'), url('../../svgs/flagBcg.svg');
  background-position: -10vw 0, 30vw 50%;
  background-repeat: no-repeat, no-repeat;
  // background-size: contain;
  // background-attachment: fixed;
  background-origin: content-box;
  background-size: auto;
  ${props => props.theme.media.lessThan('mid')`
    background-image: url('../../svgs/flagBcg.svg'), url('../../svgs/flagBcg.svg'), url('../../svgs/flagBcg.svg');
    background-position: right top, left center, left bottom;
  `}
  ${props => props.theme.media.lessThan('smallTablet')`
    background-image: url('../../svgs/flagBcg.svg'), url('../../svgs/flagBcg.svg'), url('../../svgs/flagBcg.svg');
    background-position: top center, center center, bottom center;
  `}
`

const WebinarMarketingOct2020Container = () => {
  return (
    <Wrapper>
      {/* <PageScrollHorizontalProgressBar /> */}
      <WebinarPresentation />
      <BGWrapper>
        <ParticipantsContainer />
        <Timetable />
        <References />
        <InTheNews />
        <AboutWecreate />
      </BGWrapper>

      <BookASpotContainer />
    </Wrapper>
  )
}

export default WebinarMarketingOct2020Container
