import React from 'react'
import useTranslation from '@hooks/useTranslation'

import { ReactComponent as MercedesIcon } from '@static/svgs/mercedes-benz.svg'
import { ReactComponent as HugoIcon } from '@static/svgs/hugo-boss.svg'
import { ReactComponent as GelbeIcon } from '@static/svgs/gelbe-seiten.svg'
import { ReactComponent as DKBIcon } from '@static/svgs/dkb-dark.svg'
import { ReactComponent as CookieBrosIcon } from '@static/svgs/cookie-bros.svg'

import LogosPack from './LogosPack'

const References = () => {
  const { labelStrings } = useTranslation()

  const logosList = [
    {
      Icon: MercedesIcon,
      link: 'https://www.tiktok.com/@mercedesbenz',
    },
    { Icon: DKBIcon, link: 'https://www.instagram.com/dkb.de/' },
    {
      Icon: HugoIcon,
      link: 'https://www.instagram.com/boss/',
    },
    { Icon: GelbeIcon, link: 'https://www.tiktok.com/@gelbeseiten' },
    { Icon: CookieBrosIcon, link: 'https://www.tiktok.com/@cookiebros_official' },
  ]

  return <LogosPack title={labelStrings.webinarOct2020.refs} logosList={logosList} />
}

export default References
