import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import DividerHeaderWeCreate from '@common/DividerHeaderWeCreate'
import { MaxWidthWrapper } from '@common/CommonStyles'

const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
  padding-bottom: 50px;
`

const LinkToNewPage = styled.a`
  dispaly: block;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.05);
  }
`

const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-wrap: wrap;

  width: 100%;
  justify-content: space-between;
  max-width: 900px;
  margin: 0 auto;
  ${props => props.theme.media.lessThan('mid')`
    justify-content: center;
  `}
  & svg {
    margin: 20px 20px;
  }
`

const LogosPack = ({ logosList, title }) => {
  return (
    <MaxWidthWrapper>
      <Wrapper>
        {title && <DividerHeaderWeCreate title={title} />}

        <IconsWrapper>
          {logosList.map(logo => {
            const { link, Icon } = logo
            return (
              <LinkToNewPage href={link} target='_blank' rel='noreferrer' key={link}>
                <Icon />
              </LinkToNewPage>
            )
          })}
        </IconsWrapper>
      </Wrapper>
    </MaxWidthWrapper>
  )
}

LogosPack.propTypes = {
  logosList: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

LogosPack.defaultProps = {
  logosList: [],
  title: '',
}

export default LogosPack
