import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { MaxWidthWrapper } from '@common/CommonStyles'
import { ReactComponent as FBIcon } from '@static/svgs/facebook-white.svg'
import { ReactComponent as IGIcon } from '@static/svgs/instagram-white.svg'
import { ReactComponent as TWIcon } from '@static/svgs/twitter-white.svg'
import { ReactComponent as YTIcon } from '@static/svgs/youtube-white.svg'
import { ReactComponent as LIIcon } from '@static/svgs/linkedin-white.svg'
import { ReactComponent as TTIcon } from '@static/svgs/tiktok-white.svg'
import SpeakersNav from './SpeakersNav'

const Wrapper = styled(MaxWidthWrapper)`
  max-width: 1300px;
`

const CardsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const Card = styled.div`
  position: relative;
  width: 350px;
  height: 466px;
  border-radius: 18px;
  box-shadow: 15px 30px 30px #c3c3c39a;
  background: ${props => props.theme.color.white};
  margin: 10px 20px;
  ${props => props.theme.media.lessThan('400px')`
    width: 320px;
  `}
`

const ImageWrapper = styled.div`
  position: relative;
`

const Image = styled.img`
  border-radius: 18px 18px 0px 0px;
  width: 100%;
  // height: 250px;
`

const RolesList = styled.ul`
  color: #191919;
  font-size: 15px;
  font-weight: normal;
  padding-right: 30px;
  & li:first-child {
    color: ${props => props.theme.color.cornflowerBlue2};
    font-weight: bold;
  }
`

const SocialsSection = styled.div`
  position: absolute;
  ${props => props.theme.flex.centerAll}
  justify-content: space-evenly;
  top: 10px;
  left: 10px;
  width: fit-content;
  height: 37px;
  border-radius: 11px;
  background: rgba(0, 0, 0, 0.4);
  opacity: 1;
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
`

const Link = styled.a`
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 10px;
  ${props => props.extraBottomMargin && `margin-bottom: ${props.extraBottomMargin}px;`}
  &:hover {
    // transform: scale(1.03);
  }
`

const Name = styled.div`
  font-family: ${props => props.theme.fontFamily.brush};
  text-shadow: 0px 0px 20px #000000eb;
  color: ${props => props.theme.color.white};
  font-size: 55px;
  position: absolute;
  top: 50%;
  right: 20px;
  text-align: right;
  ${props => props.theme.media.lessThan('mobile')`
    font-size: 50px;
    top: 50%;
  `}
  ${props => props.theme.media.lessThan('350px')`
    font-size: 50px;
    top: 44%;
  `}
`

const ParticipantsSection = ({ list, selectedNav, idsForScroll, id, withHeader }) => {
  const linkCommonProps = {
    target: '_blank',
    rel: 'noreferrer',
  }

  return (
    <Wrapper id={id}>
      <SpeakersNav selectedNav={selectedNav} idsForScroll={idsForScroll} withHeader={withHeader} />
      <CardsWrapper>
        {list.map(item => {
          return (
            <Card key={item.firstName + item.lastName}>
              <ImageWrapper>
                <Image src={`../../images/webinars/${item.imagename}`} alt='speaker' />
                <Name>
                  {item.firstName} <br /> {item.lastName}
                </Name>
              </ImageWrapper>

              <RolesList>
                {item.roles.map(role => {
                  return <li key={role}>{role}</li>
                })}
              </RolesList>

              <SocialsSection>
                {item.links.facebook && (
                  <Link href={item.links.facebook} {...linkCommonProps}>
                    <FBIcon />
                  </Link>
                )}
                {item.links.instagram && (
                  <Link href={item.links.instagram} {...linkCommonProps} extraBottomMargin={1}>
                    <IGIcon />
                  </Link>
                )}
                {item.links.tiktok && (
                  <Link href={item.links.tiktok} {...linkCommonProps}>
                    <TTIcon />
                  </Link>
                )}
                {item.links.linkedin && (
                  <Link href={item.links.linkedin} {...linkCommonProps} extraBottomMargin={3}>
                    <LIIcon />
                  </Link>
                )}
                {item.links.twitter && (
                  <Link href={item.links.twitter} {...linkCommonProps}>
                    <TWIcon />
                  </Link>
                )}
                {item.links.youtube && (
                  <Link href={item.links.youtube} {...linkCommonProps}>
                    <YTIcon />
                  </Link>
                )}
              </SocialsSection>
            </Card>
          )
        })}
      </CardsWrapper>
    </Wrapper>
  )
}

ParticipantsSection.propTypes = {
  list: PropTypes.array,
  selectedNav: PropTypes.string,
  id: PropTypes.string,
  idsForScroll: PropTypes.object,
  withHeader: PropTypes.bool,
}

ParticipantsSection.defaultProps = {
  list: [],
  selectedNav: '',
  id: '',
  idsForScroll: {},
  withHeader: false,
}

export default ParticipantsSection
