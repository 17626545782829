import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import BookOct2020WebinarButton from '@common/buttons/BookOct2020WebinarButton'
import { YellowButton } from '@common/buttons/commonButtons'

import useTranslation from '@hooks/useTranslation'

// import ContactUsContainer from '@containers/ContactUsContainer'
import { PASSED_DATES } from '@constants/appSettings'
import { getCurrentDateMS } from '@utils'

const ContainerWrapper = styled.div`
  position: fixed;
  ${props => props.theme.flex.centerAll}
  right: ${props => (props.hasWebinarPassed ? 5 : 10)}%;
  top: ${props => (props.hasWebinarPassed ? 5 : 10)}%;
  z-index: 1000;
  ${props => props.theme.media.lessThan('smallDesktop')`
    top: 5%;
    right: ${props.hasWebinarPassed ? '5%' : '0'};
    left: ${props.hasWebinarPassed ? 'unset' : '0'};
  `}

  & #contact_modal {
    & * {
      color: ${props => props.theme.color.white};
    }
    & input,
    textarea,
    button {
      color: ${props => props.theme.color.black};
    }
  }
`

const QuestionButton = styled(YellowButton)`
  width: 35px;
  height: 33px;
  margin: 0 10px;
  font-size: 18px;
  font-weight: bold;
  ${props => props.theme.media.lessThan('tablet')`
    font-size: 18px;
    
  `}
`

const BookASpotContainer = ({ withContact }) => {
  const { labelStrings } = useTranslation()
  const hasWebinarPassed = getCurrentDateMS() > PASSED_DATES.oct24th
  return (
    <ContainerWrapper>
      <BookOct2020WebinarButton
        label={hasWebinarPassed ? labelStrings.webinarOct2020.buyMasterclass : labelStrings.webinarOct2020.bookASpot}
      />
      {/* {withContact && (
        <ContactUsContainer CustomButton={({ onClick }) => <QuestionButton onClick={onClick}>?</QuestionButton>} />
      )} */}
    </ContainerWrapper>
  )
}

BookASpotContainer.propTypes = {
  withContact: PropTypes.bool,
}

BookASpotContainer.defaultProps = {
  withContact: true,
}

export default BookASpotContainer
