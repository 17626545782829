import React from 'react'
import Helmet from 'react-helmet'
import WeCreatePageSeoContainer from '@containers/WeCreatePageSeoContainer'
import WeCreateWebinarOct2020PageContainer from '@containers/WeCreateWebinarOct2020PageContainer'

const IndexPage = () => {
  return (
    <>
      {/* SEO */}
      <WeCreatePageSeoContainer
        seoTitle='Influencer Marketing Fest | weCreate'
        seoDesc='Die Top-Experten zu TikTok, Instagram, YouTube und LinkedIn alle in einem Webinar: das Influencer Marketing Fest von weCreate'
      />
      <Helmet>
        <script src='https://js.chargebee.com/v2/chargebee.js' data-cb-site='paymentpunks' />
      </Helmet>

      {/* content container */}
      <WeCreateWebinarOct2020PageContainer />
    </>
  )
}

export default IndexPage
